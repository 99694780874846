/* You can add global styles to this file, and also import other style files */
@font-face {
    font-family: 'Gotham-Regular';
    src: url("/assets/fonts/gotham/Gotham-Light.otf") format("truetype");
}

@font-face {
    font-family: 'Gotham-Thin';
    src: url("/assets/fonts/gotham/Gotham-Thin.otf") format("truetype");
}

@font-face {
    font-family: 'Gotham-Medium';
    src: url("/assets/fonts/gotham/GothamMedium.ttf") format("truetype");
}

@font-face {
    font-family: 'Gotham-Book';
    src: url("/assets/fonts/gotham/GothamBook.ttf") format("truetype");
}

@import '../node_modules/angular-archwizard/styles/archwizard.scss';
@import '../node_modules/ngx-ui-switch/ui-switch.component.scss';
@import "../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";

.wizard-design {
    ul {
        &.steps-indicator {
            li {
                .label {
                    color: grey
                }

                &:after {
                    content: none !important;
                    background-color: grey !important;
                }

                .step-indicator {
                    left: 10px !important;
                    width: 90% !important;
                    border-radius: 10px !important;
                    background-color: grey !important;
                }
            }



            li.current {
                .step-indicator {
                    background-color: #add2ef !important;
                }

                .label {
                    color: #0061b7 !important;
                }
            }

            li.optional {
                .step-indicator {
                    background-color: rgb(146, 144, 143) !important;
                }

                .label {
                    color: rgb(146, 144, 143) !important;
                }
            }

            li.done {
                .step-indicator {
                    background-color: rgb(0, 0, 0) !important;
                }

                .label {
                    color: black !important;
                }
            }

        }
    }
}

/* variables start */
:root {

    /* Colors: */
    --unnamed-color-a6adb4: #A6ADB4;
    --unnamed-color-afafaf: #AFAFAF;
    --unnamed-color-ebe9eb: #EBE9EB;
    --unnamed-color-7ba3eb: #7BA3EB;
    --unnamed-color-ff4b00: #FF4B00;
    --unnamed-color-f1f3f9: #F1F3F9;
    --unnamed-color-707070: #707070;
    --unnamed-color-000000: #000000;
    --unnamed-color-818181: #818181;
    --unnamed-color-7764e4: #7764E4;
    --unnamed-color-666666: #666666;
    --unnamed-color-ffffff: #FFFFFF;
    --unnamed-color-172b4d: #172B4D;

    /* Font/text values */
    --unnamed-font-family-gotham: Gotham;
    --unnamed-font-family-helvetica-neue: Helvetica Neue;
    --unnamed-font-style-normal: normal;
    --unnamed-font-weight-normal: normal;
    --unnamed-font-weight-medium: medium;
    --unnamed-font-weight-bold: bold;
    --unnamed-font-size-14: 14px;
    --unnamed-font-size-17: 17px;
    --unnamed-font-size-18: 18px;
    --unnamed-font-size-20: 20px;
    --unnamed-character-spacing-0: 0px;
    --unnamed-character-spacing-2-8: 2.8px;
    --unnamed-line-spacing-20: 20px;
    --unnamed-line-spacing-22: 22px;
    --unnamed-line-spacing-24: 24px;
}

/* Character Styles */
.unnamed-character-style-1 {
    font-family: var(--unnamed-font-family-helvetica-neue);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-normal);
    font-size: var(--unnamed-font-size-20);
    line-height: var(--unnamed-line-spacing-24);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-707070);
}

.unnamed-character-style-2 {
    font-family: var(--unnamed-font-family-gotham);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-normal);
    font-size: var(--unnamed-font-size-14);
    line-height: var(--unnamed-line-spacing-22);
    letter-spacing: var(--unnamed-character-spacing-2-8);
    color: var(--unnamed-color-000000);
}

.unnamed-character-style-3 {
    font-family: var(--unnamed-font-family-gotham);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-normal);
    font-size: var(--unnamed-font-size-14);
    line-height: var(--unnamed-line-spacing-22);
    letter-spacing: var(--unnamed-character-spacing-2-8);
    color: var(--unnamed-color-ffffff);
}

.unnamed-character-style-4 {
    font-family: var(--unnamed-font-family-gotham);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-normal);
    font-size: var(--unnamed-font-size-18);
    line-height: var(--unnamed-line-spacing-22);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-000000);
}

.unnamed-character-style-5 {
    font-family: var(--unnamed-font-family-gotham);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-medium);
    font-size: var(--unnamed-font-size-17);
    line-height: var(--unnamed-line-spacing-20);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-000000);
}

.unnamed-character-style-6 {
    font-family: var(--unnamed-font-family-gotham);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-bold);
    font-size: var(--unnamed-font-size-18);
    line-height: var(--unnamed-line-spacing-22);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-172b4d);
}

/* variables end */

.btn-custom {
    border: none;
    background-color: white;
    border-radius: 7px;
    font-size: 0.9em;
    padding: 0px 8px 0px 8px;
    min-height: 30px;
    font-family: 'Gotham-Book' !important;

    &:hover {
        background-color: orangered;
    }
}

ul.pagination {
    margin-bottom: 0 !important;

    .page-item.active a {
        &:hover {
            background-color: #E6E6E6;
        }

        background-color: #E6E6E6;
    }
}




.dropdown-custom {
    button {
        background-color: white;
        color: black;
        border-radius: 4px;
        height: 30px;
        line-height: 1;

        &:hover {
            background-color: orangered;
            color: black;
        }
    }

    .btn-dd::after {
        display: inline-block;
        margin-left: 0.255em;
        vertical-align: 0.255em;
        content: "";
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent;
    }
}

.select-custom {
    height: 30px;
    line-height: 1 !important;
    font-size: 0.8em !important;

    option {
        padding: 5px;
    }
}

.card-custom {
    padding: 15px;

    .card-header {
        background-color: #EBE9EB;
    }

    .card-body {
        padding-left: 0;
        padding-right: 0;
    }
}

.dv-custom-navs {
    background-color: #e9e9ef;

    ul.nav-tabs {
        border-bottom: 5px solid white !important;
    }

    li.nav-item {
        cursor: pointer;
        @media screen and (max-width: 767px) {
            i-feather {
                display: none;
            }
            a {
                font-size: 12px;
            }
        }
        @media screen and (max-width: 480px) {
            min-width: 15.5vw;
            width: auto;
            left: 0px !important;
        }
        width: auto;
        background-color: #e0e0e0cd; /* Light grey background */
        // padding: 10px 30px; /* Padding to make the tab labels bigger */
        // margin-right: 10px; /* Space between tabs */
        @media screen and (min-width: 481px) {
            clip-path: polygon(0 0, 80% 0, 100% 100%, 0% 100%); /* Trapezoid shape */
        }
        transition: all 0.3s;
        border-top: 0px !important;
        border-right: 0px !important;
        position: relative;
        &:first-child {
            border-left: 0px solid black !important;
        }
        a {
            cursor: pointer;
            border: 0 !important;
            border-radius: 0 !important;
            text-align: center;
            justify-content: flex-start !important;
            padding: 10px 2.5vw 10px 12.5px; /* Padding to make the tab labels bigger */
            @media screen and (min-width: 481px) and (max-width: 767px) {
                font-size: 1.65vw;;
            }
            label {
                cursor: pointer;
                text-align: start;
                color: black;
            }
            &.active {
                background-color: rgba(255, 255, 255,) !important; /* Light grey background */
                // margin-right: 10px; /* Space between tabs */
                transition: all 0.3s;
                color: black !important;
            }
        }

        &:first-child {
            border-left: 1px solid black;
        }
    }

}


.btn-page {
    background-color: #EBE9EB !important;
    font-weight: bold;
    width: 80px;
}

.btn-page-orange {
    background-color: #fd625e !important;
    font-weight: bold;
    color: white !important;
}

.btn-black {
    background: black !important;
    color: white !important;
    border: none !important;
    border-radius: 5px !important;
    padding: 5px 10px 5px 10px !important;
    border-color: black !important;
    box-shadow: 0 2px 6px 0 rgb(0 0 0 / 50%) !important;
}

.color-anchor {
    color: #516079 !important
}

.mb-10 {
    margin-bottom: 10px !important;
}

.mt-10 {
    margin-top: 10px !important;
}

button.close[aria-label='Close'] {
    border: none;
    background: white;
    font-size: 20px;

    &:hover {
        color: #fd625e;
    }
}

.a-btn {
    color: black;
}

ng-select {
    &.is-invalid {
        border: 1px solid #fd625e;
        border-radius: 5px !important;
    }
}

.disabled-dropdown {
    pointer-events: none;
    opacity: 0.7;
    cursor: not-allowed;
}


.tooltip-roles-ddl {
    .tooltip-inner {
        padding-left: 0px;
        padding-right: 0px;

        p {
            margin: 0;
        }
    }
}

aw-wizard-navigation-bar {
    margin-bottom: 40px;
    min-width: 80%;
    align-self: center;
}


.row-validation {
    color: red;
    font-size: 11.2px;
    font-weight: 200;

}

.mb-minus-3 {
    margin-top: -0.8rem !important;
    margin-bottom: 1rem !important;
}

.center-label {
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.strike {
    text-decoration: line-through !important;
}

.blue-button {
    background-color: #add2ef;
    color: black;
    padding: 8px 12px 8px 12px;
    font-size: 14px;
    min-height: 35px;
    min-width: 100px;
    border-radius: 4px;

    &:disabled,
    &.disabled,
    fieldset:disabled & {
        opacity: 50%;
    }
}

.gray-button {
    background-color: #bdbdbd;
    color: black;
    padding: 8px 12px 8px 12px;
    font-size: 14px;
    min-height: 35px;
    min-width: 100px;
    border-radius: 4px;

    &:disabled,
    &.disabled,
    fieldset:disabled & {
        opacity: 50%;
    }

    label {
        margin-bottom: 0;
        cursor: pointer;
        display: flex;
        align-items: center;
      }
      input {
        margin-right: 5px;
      }
}

.modal-content {
    background-color: transparent ! IMPORTANT;
    border: none ! IMPORTANT;
}

.modal {
    z-index: 1055 !important;
}

.modal-box {
    border: solid 1px #ccc;
    background: #fff;
    border-radius: 4px;
    position: relative;
    z-index: 1 !important;
    transition: box-shadow 200ms cubic-bezier(0, 0, 0.2, 1);
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
                0 2px 2px 0 rgba(0, 0, 0, 0.14),
                0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.btn-orange
{
    color: orangered;
    border: 1px solid orangered;
    &:focus,
    &:active {
        border: none;
        box-shadow: none;
    }
    &:hover{
        cursor: pointer;
        color: black;
    }
}