
//
// Range slider
//

.ngx-slider {
  .ngx-slider-pointer {
    background-color: $primary !important;
    width: 18px !important;
    height: 18px !important;
    top: -7px !important;
    outline: none !important;

    &:after {
      top: 5px !important;
      left: 5px !important;
    }
  }

  .ngx-slider-active {
    &:after {
      background-color: $white !important;
    }
  }

  .ngx-slider-tick {
    left: -3px !important;

    &.ngx-slider-selected  {
      background: $primary !important;
    }
  }

  .ngx-slider-tick-value {
    left: 1px !important;
    top: -25px !important;
  }

  .ngx-slider-tick-legend {
    top: 14px !important;
    text-transform: capitalize;
    font-size: 0.7rem;
  }

  .ngx-slider-selection {
    background: $primary !important;
  }
}

// Colorpicker

#red, #green, #blue {
	margin: 10px;
	display: inline-block;
	height: 200px;
}

#colorpicker {
	height: 240px;
	width: 310px;
	margin: 0 auto;
	padding: 10px;
	border: 1px solid $border-color;
}

#result {
	margin: 60px 26px;
	height: 100px;
	width: 100px;
	display: inline-block;
	vertical-align: top;
	border: 1px solid $border-color;
    box-shadow: 0 0 3px;
    border-radius: 7px;
}

#red {
    .noUi-connect {
        background: $danger;
    }
}

#green {
    .noUi-connect {
        background: $success;
    }
}

#blue {
    .noUi-connect {
        background: $primary;
    }
}

// Adding keyboard support

.form-control{
    &.keyboard{
        max-width: 340px !important;
    }
}

// Using HTML5 input elements

#input-select,
#input-number {
	padding: 7px;
	margin: 15px 5px 5px;
	width: 70px;
}

// Non linear slider

.example-val{
    font-size: 12px;
    color: $text-muted;
    display: block;
    margin: 15px 0;
    &:before{
        content: "Value: ";
        font-size: 12px;
        font-weight: 600;
    }
}

// showing tooltips

.noUi-tooltip {
    display: none;
}
.noUi-active .noUi-tooltip {
    display: block;
}

// Colored Connect Elements

.c-1-color { background: $danger; }
.c-2-color { background: $warning; }
.c-3-color { background: $success; }
.c-4-color { background: $primary; }
.c-5-color { background: $purple; }

//  slider togle

#slider-toggle {
    height: 50px;
    &.off{
        .noUi-handle{
            border-color: $danger;
        }
    }
}

body[data-layout-mode="dark"] {
  .noUi-target{
    background-color: $gray-dark-300;
    border-color: $gray-dark-300;
    box-shadow: inset 0 1px 1px $gray-dark-300, 0 3px 6px -5px $gray-dark-300;
  }

  .example-val{
    color: $gray-dark-400;
  }
}
