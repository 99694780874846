// 
// toasts.scss
//

body[data-layout-mode="dark"] {
    .toast, .toast-header{
        background-color: rgba($gray-dark-300, 0.85);
        color: $gray-dark-400;
    }

    .toast-header{
        color: $gray-dark-500;
    }

}

.toast-header .close{
    margin: 0 0 0 10px !important;
    background-color:transparent;
    padding: 0;
    border: none;
}
.toast-header .close span{
    font-size: 35px;
    line-height: 22px;
    color: $secondary;
}